<template>
    <div class="bloc__openVideo" @wheel.prevent="disabled_wheel" @mousewheel.prevent="disabled_wheel" :class="{'animPaint__fadeOut': brushAnim, 'videoHidden':watchVideo}">
        <div class="bloc__openVideo-ctn">
            <vue-player
                video
                :sources="videoSources"
                :poster="'videos/OpenImage.jpg'"
            />
            <div class="btn-pos">
                <button id="btn-play" type="button" class="btn btn-OpenVideo btn-video__play" @click="playVideo"></button>
                <button id="btn-skip" type="button" class="btn btn-OpenVideo" @click="skipVideo">{{$t('skip')}}</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default{
        name: 'OpeningVideo',
        props: {
            msg: String,
        },
        data() {
            return{
                watchVideo: false,
                brushAnim: false,
                videoSources: {
                    "video/mp4":
                    "videos/openVideo.mp4",
                    "video/webm":
                    "videos/openVideo.webm"
                }
            }
        },

        methods:{
            playVideo(){
                var video = document.querySelector(".bloc__openVideo-ctn video")
                document.querySelector(".btn-video__play").style.opacity = 0;
                video.play()
                
                setTimeout(() => {
                    document.querySelector(".btn-video__play").style.display = "none";
                }, 500);
            },
            skipVideo(){
                var videoCtnBloc = document.querySelector(".bloc__openVideo")
                 setTimeout(() => {
                    this.brushAnim = true;
                    document.querySelector('.logo-bandeau-white').classList.add('slide-in-top')
                    document.querySelector(".bloc__openVideo-ctn video").pause()
                }, 300);
                setTimeout(() => {
                    videoCtnBloc.classList.add('hideBlock');
                }, 1500);
            },
            disabled_wheel(e){
                e.stopPropagation();
                return false;
            }
        },

        mounted(){
            var video = document.querySelector(".media")
            video.addEventListener("ended", this.skipVideo)
        }
    }
</script>